@import "../global-styles/color-style.scss";

// common
.h-100 {
  height: 100%;
}

.flex-container {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  min-width: 99vw;
  max-width: 100vw;
}

.content {
  overflow-y: scroll;
  max-height: 100%;
}

.layout-parent{
  max-height: 99%;
  overflow-y: visible;
}

.parent-of-all{
  display: flex;
  flex-direction: column;
  height: 99%;
  max-height: 99%;
}