section.ant-layout.h-100 {
  overflow: hidden;
}

//slogData-Table -style

.slogdata-status {
  .table-container {
    overflow-x: auto;
    width: 100%;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
    border-right: 1px solid $Grey;
  }

  .ant-table table {
    border-collapse: separate;
  }

  table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
    border-right: 1px solid $Grey;
  }

  .ant-table-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: center;
  }

  .ant-table .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 15px;
    text-align: center;
  }

  .ant-table .ant-table-container table>thead>tr:last-child {
    border-top-right-radius: 12px;
  }

  .ant-table .ant-table-container table>thead>tr:first-child th:last-child {
    border: none;
    text-align: center;
  }

  table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
    border-right: none;
    text-align: center;
  }

  .ant-table .ant-table-container table>thead>tr>th {
    padding: 15px;
    text-align: center;
    vertical-align: middle;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    border: 1px solid $Grey;
    text-align: center;
    vertical-align: middle;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border: 1px solid $Grey;
    padding: 12px;
    text-align: center;
    vertical-align: middle;
  }

  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: 1px solid $Grey;
    border-top-left-radius: 15px;
    border-right: 1px solid $Grey;
    border-top-right-radius: 15px;
    border-right: none;
  }

  tr:nth-child(even) td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  tr:nth-child(even) td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .ant-table-body {
    border-bottom: 1px solid $Grey;
  }

  tr.ant-table-row.ant-table-row-level-0 {
    height: 39.5px;
  }

  td.ant-table-cell {
    border: 1px solid $Grey;
    text-align: center;
    vertical-align: middle !important;
  }

  th.ant-table-cell {
    border: 1px solid $Grey;
    text-align: center;
    vertical-align: middle;
  }

  .ant-table .ant-table-container table>tbody>tr>td {
    cursor: pointer;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 2px;
  }
}

//Zone View and Pass View table style
.zoneview-table {
  .ant-table .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 15px;
    text-align: center;
  }

  .ant-table table {
    width: 0% !important;
  }

  // .ant-table .ant-table-container table > thead > tr > th:nth-child(19) {
  //   border-top-right-radius: 12px;
  // }

  .ant-table .ant-table-container table>thead>tr:first-child th:last-child {
    border: none;
    text-align: center;
  }

  .ant-table .ant-table-container table>thead>tr>th {
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    border-top: 2px solid $Grey;
  }

  tr:nth-child(even) td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  tr:nth-child(even) td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .ant-table-body {
    border-bottom: 1px solid $Grey;
  }

  tr.ant-table-row.ant-table-row-level-0 {
    height: 39.5px;
  }

  td.ant-table-cell {
    border: 1px solid $Grey;
    text-align: center;
    vertical-align: middle !important;
  }

  th.ant-table-cell {
    border: 1px solid $Grey;
    text-align: center;
    vertical-align: middle;
  }

  .ant-table .ant-table-container table>tbody>tr>td {
    cursor: pointer;
    border-top: 2px solid $Grey;
  }

  // .ant-table-wrapper .ant-spin-nested-loading .ant-table-thead>tr>th,
  // .ant-table-wrapper .ant-spin-container .ant-table-thead>tr>th,
  // .ant-table-wrapper .ant-table .ant-table-thead>tr>th {
  //     border-top: 2px solid $Grey !important;
  // }
}

.reduced-width-table {
  display: flex;
  max-height: 100%;
  margin: 0;

  .ant-spin-nested-loading {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 99%;
      max-height: 99%;

      .ant-table {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: hidden;
        min-width: 100%;
        height: 100%;

        .ant-table-container {
          display: flex;
          flex-direction: column;
          overflow-y: hidden;
          height: 99%;

          .ant-table-header {
            height: 100%;
            min-height: 53px;
          }

          .ant-table-body {
            display: flex;
            flex-grow: 1;
            overflow-y: hidden;
            justify-content: flex-start;
            align-items: flex-start;
            height: 70%;
            min-height: 90%;
            max-height: 99%;
          }
        }
      }
    }
  }

  .ant-table .ant-table-container table>thead>tr>th:nth-child(4) {
    border-top-right-radius: 12px;
  }

  @media (max-height: 520px) {
    .ant-table-body {
      max-height: 278px !important;
    }
  }

  @media (min-height: 720px) and (max-height: 832px) {
    .ant-table-body {
      max-height: 300px;
    }
  }

  @media (min-height: 832px) and (max-height: 956px) {
    .ant-table-body {
      max-height: 500px;
    }
  }

  @media (min-height: 956px) and (max-height: 1112px) {
    .ant-table-body {
      max-height: 556px !important;
    }
  }

  @media (min-height: 1024px) and (max-height: 1120px) {
    .ant-table-body {
      max-height: 630px !important;
    }
  }

  @media (min-height: 1120px) and (max-height: 1900px) {
    .ant-table-body {
      max-height: 730px !important;
    }
  }

}

h6.filter_by {
  margin-top: 8px;
  margin-right: 18px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 20px;
  padding: 4px;
  width: 13pc;
  background: $table-bg-white;
}

.ant-select-arrow {
  line-height: 0;
  position: absolute;
  top: 75%;
  line-height: 1;
}

.ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.ant-select-selection-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 28px;
}

.ant-select-selection-item {
  width: 100%;
  border-radius: 16px;
}

.ant-select-selection-overflow-item {
  width: 10pc;
  padding-bottom: 3px;
}

span.ant-select-selection-item-remove {
  position: absolute;
  right: 6px;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 14px;
}

span.ant-select-clear {
  background: none;
  position: absolute;
  top: 16px;
  font-size: 15px;
}

span.anticon.anticon-left {
  vertical-align: middle;
}

span.anticon.anticon-right {
  vertical-align: middle;
}

.ant-select-arrow .anticon {
  position: relative;
  bottom: 8px;
}

span.anticon.anticon-calendar {
  color: $baseRed;
}

.ant-picker {
  margin-bottom: 15px;
}

.slog-NoData {
  text-align: center;
  margin-top: 7pc;
}

//slog-weld Page style
.slogweld-title {
  display: flex;
  margin-bottom: 12px;

  h2.slogweld-header-weldnuber {
    font-size: 22px;
    width: 100%;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    text-overflow: ellipsis;
  }

  .boundary-input {
    display: flex;
    margin-left: 0px;

    label.tolerance-lable {
      font-size: 15px;
      margin-top: 3px;
      margin-right: 6px;
    }

    .ant-input-number.re-generate-input {
      height: 34px;
      width: 55px;
    }

    .re-generate-input .ant-input-number-handler-wrap {
      display: none;
    }

    .re-generate-btn,
    .re-generate-btn-save {
      border-radius: 18px;
      margin-left: 10px;

      .ant-btn-primary[disabled] {
        border-radius: 16px;
      }
    }

    .re-generate-btn-save-pd,
    .re-generate-btn-save-pd-disable {
      margin: 3px 10px;
      height: 24px;
    }

    .re-generate-btn-save-pd-disable {
      margin-right: 2px;
      cursor: no-drop;
    }


    .re-generate-btn-save-pd:hover {
      color: $baseWhite;
      cursor: pointer;
    }

    .re-generate-btn:hover,
    .re-generate-btn-save:hover {
      color: $baseWhite;
    }

    button.ant-btn.ant-btn-default.refresh-download {
      border: none;
      padding: 2px;
      margin-right: 12px;
      color: $baseWhite;

      span.anticon.anticon-reload {
        position: relative;
        bottom: 12px;
      }
    }
  }
}

span.anticon.anticon-left.leftoutlined {
  color: $baseWhite;
  font-size: 27px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

h2.slogweld-header {
  // margin-right: 12px;
  font-size: 22px;
}

h2.slogweld-header-active {
  color: $baseRed;
  margin-right: 12px;
  font-size: 22px;
  white-space: nowrap;
}

button.ant-btn.ant-btn-primary.resetbtn {
  border-radius: 19px;
  height: 38px;
  color: $baseWhite;
}

span.anticon.anticon-menu-fold {
  vertical-align: text-top;
}

button.ant-btn.ant-btn-secondary.column-option {
  margin-left: auto;
  border-radius: 16px;
  color: $baseRed;
  border-color: $baseRed;
}

//slog table responsive view style

@media (min-width: 2000px) and (max-width: 10000px) {
  .reduced-width-table {
    max-width: 10000px;
    margin: 0 auto;
  }
}

//project-details hide and show styles
.project-details-hide {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

span.anticon.anticon-down-circle.downoutlined-icon {
  font-size: 18px;
  color: $baseRed;
  margin-top: 0px;
  margin-right: 6px;
}

span.anticon.anticon-down-circle.downoutlined-icon-projectList {
  font-size: 18px;
  color: $baseRed;
  margin-top: 1.5px;
  margin-right: 6px;
}

h6.p-details-title {
  white-space: nowrap;
}

.custom-line.mb-3.p-d {
  margin-top: 9px;
  margin-left: 12px;
}

span.anticon.anticon-up-circle.upoutlined {
  cursor: pointer;
  color: $baseWhite;
  font-size: 18px;
  color: $baseRed;
  margin-top: 1%;
}

.p-d-f {
  position: relative;
  right: 49%;
  top: 27px;
}

.project-detail-bar {
  margin-left: 30px;
  position: relative;
  margin-top: -18px;
}

//drawer style
.drawer {
  .ant-drawer-content {
    background: url("../assets/bg.jpg") no-repeat center center;
    background-size: cover;
  }

  .ant-drawer-title {
    margin-top: 4px;
  }
}

//slog-data filter
.filter_form {
  .ant-select-multiple .ant-select-selection-placeholder {
    right: 108px;
  }
}

//tlog-Data style

.ant-tabs-tab .anticon {
  margin-right: 6px;
  position: relative;
  bottom: 3px;
}

.tlog-detail-bar {
  margin-bottom: 20px;
}

.tlogdata-detail-loader {
  margin-bottom: 12px;
}

.tlogtable-style {

  .ant-table.ant-table-bordered>.ant-table-container {
    border: none;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
    border: none;
  }

  .ant-table .ant-table-container table>thead>tr>th:nth-child(19) {
    border-top-right-radius: 12px;
  }

  .ant-table th:nth-child(1),
  .ant-table th:nth-child(1) {
    width: 10px;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
    white-space: break-spaces;
  }
}

.red-note {
  background: $baseRed;
  border-radius: 12px;
  padding: 5px;
  color: $baseWhite;
}

//PDF Download style

button.ant-btn.ant-btn-primary.reportPdfdownload {
  margin-right: 15px;
  border-radius: 16px;
  color: $baseWhite;
}

//side bar logo align center while collaps
.logo-container .logo-collapsed .aside-logo-small img {
  width: 100%;
  margin-left: 12px;
}

.report-download {
  .ant-radio-inner::after {
    background-color: $baseRed;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $baseRed;
  }

  .ant-radio:hover,
  .ant-radio-inner {
    border-color: $baseRed !important;
  }

  .ant-form input[type="radio"]:focus {
    outline: none !important;
  }

  label.ant-radio-wrapper {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.map-parent {
  display: flex;
  flex-grow: 1;
  border-radius: 10px;

  .map-box-container {
    display: flex;
    flex-grow: 1;
    height: 100%;
    border-radius: 10px;
  }
}

//Email Style

.email-popup-text {
  button:focus {
    outline: none;
    border-color: $baseWhite-transparant;
    color: $baseWhite;
  }

  .email-popup-text {
    margin-top: 10px;
  }

  .modal-email-button {
    margin: 10px 0px 10px 5px;
    border-radius: 18px;
  }

  .modal-email-button:hover {
    border: 1px solid $baseWhite;
    color: $baseWhite;

  }

  .modal-email-button:active {
    border: 1px solid $baseRed;
  }

  .email-cancel-button {
    padding: 7px;
    width: 12px;
    height: 17px;
    margin-left: -2px;
    margin-top: 0px;
    border: 0;

    .anticon {
      vertical-align: top;
      margin-left: -7px;
      margin-top: -7px;
    }
  }

  .email-cancel-button:hover {
    border: 0;

    .anticon {
      color: $baseRed;
    }
  }

  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
  }

  button.ant-btn.ant-btn-primary.add-email {
    position: relative;
    right: 21px;
    bottom: 13px;
  }
}