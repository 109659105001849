@import "./color-style.scss";

.light-theme {
    h6.p-details-title {
        white-space: nowrap;
        color: $baseBlack;
    }
    span.anticon.anticon-up.upoutlined {
        cursor: pointer;
        color: $baseBlack;
    }
}