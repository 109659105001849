.pqr-report-div {
  display: flex;

    button.ant-btn.ant-btn-primary.pqrButton {
        margin-left: 15px;
        border-radius: 16px;
        color: #fff;
    }

    .selected-weld-numbers {
        margin-left: 12px;

        span.ant-tag.options-show {
            padding: 5px;
            border-radius: 18px;

            span.anticon.anticon-close.ant-tag-close-icon {
                position: relative;
                bottom: 3px;
            }
        }
    }
}

.pqrstyle th.ant-table-cell {
  white-space: break-spaces;
}

@media (min-width: 500px) and (max-width: 1200px) {
  .weld-div {
    .weld-flex {
      .selectweld {
        margin: 1% 3% 2% 0%;
      }
    }
  }
}

.header {
    display: grid;
    grid-template-columns: 0.3fr 0.5fr 0.7fr 0.5fr 0.7fr;
    ;
    background-color: $input-bg;
    color: $baseRed;
    padding: 14px;
    border-radius: 26px;
    margin-bottom: 20px;
    font-weight: bold;
    margin-top: 20px;
    width: 70%;
}

.pqr-gen-div {
  width: 75%;

    .row {
        display: grid;
        grid-template-columns: 0.3fr 0.5fr 0.7fr 0.5fr 0.7fr;
        ;
        background-color: rgba(255, 255, 255, 0.05);
        padding: 20px;
        border-radius: 14px;
        margin-bottom: 10px;
        align-items: center;
        width: 93%;
        position: relative;
        left: 12px;
    }

  .weld-numbers {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .weld-number {
    padding: 5px 10px;
    background: $input-bg;
    border-radius: 6px;
  }

  .document-icons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .document-icon {
    cursor: pointer;
    color: $baseRed;

    img.Pdf-disabled {
      height: 22px;
    }

    img.Passvie-pdf {
      height: 22px;
    }
  }

  .percentage {
    padding: 5px 10px;
    display: inline-block;

    span.boundary-percetage {
      background-color: $input-bg;
      padding: 10px;
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 8px;
    }
  }

  .action-icons {
    display: flex;
    gap: 30px;
    justify-content: center;

    span.viewanddownloadeye {
      cursor: pointer;
      font-size: 23px;
      color: $baseRed;
      position: relative;
      z-index: 1;
      display: contents;
    }

    span.viewanddownload {
      cursor: pointer;
      color: $baseRed;
      position: relative;
      z-index: 1;

      // display: contents;
      span.anticon.anticon-download {
        font-size: 23px;
      }
    }

    span.viewanddownload-disable {
      cursor: not-allowed;
      pointer-events: auto;
      position: relative;
      z-index: 1;
      color: $Grey;

      span.anticon.anticon-download {
        font-size: 23px;
      }
    }
    span.share-pqr-report {
      cursor: pointer;
      color: $baseRed;
      position: relative;
      z-index: 1;
      span.anticon.anticon-send {
        font-size: 20px;
      }
    }
    span.share-pqr-report-disable {
      cursor: not-allowed;
      pointer-events: auto;
      position: relative;
      z-index: 1;
      color: $Grey;
      span.anticon.anticon-send {
        font-size: 20px;
      }
    }
  }
}

.ant-tooltip-inner {
  text-align: center !important;
}
