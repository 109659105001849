.tlog-graph {
    .grapview-selector {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .graph-select {
            .ant-select-selector {
                border-radius: 16px !important;
            }
        }

        .ant-select.graph-select.ant-select-single.ant-select-show-arrow {
            width: 16%;
        }
    }

    // .graph-slider {
    //     margin-top: 30px;

    //     .ant-slider-rail {
    //         background: $baseWhite;
    //     }

    //     .ant-slider-track {
    //         background: $baseRed;
    //     }

    //     .ant-slider-handle {
    //         border: 2px solid $baseRed;
    //         background: $baseWhite;
    //     }
    // }

    .apexcharts-menu.apexcharts-menu-open {
        background: $baseBlack;
    }

    .apexcharts-menu.apexcharts-menu-open :hover {
        background: $baseWhite;
        color: $baseBlack;
    }

}

//IWM Graph page style
.iwm-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .iwm-selector {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            position: relative;
            background-color: transparent;
            border: 1px solid #434343;
            border-radius: 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            height: 40px;
            padding: 5px 0px 0px 12px;
            width: 14pc;
        }
    }

    .iwm-checkbox {
        background: $table-bg-white;
        border-radius: 27px;
        padding: 12px;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

.iwm-graph .single-line-graph {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Graph-background{
    background: $table-bg-white;
    border-radius: 12px;
    margin: 12px;
}
